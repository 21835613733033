import { observer } from "mobx-react-lite";
import React, { Fragment } from "react";
import { IBlogStore, IMessage } from "../pages/Admin/Blogs/BlogStore";
import {} from "../pages/Admin/Blogs/NewBlog";

interface IEditor {
  blogStore: IBlogStore;
}

interface IErrorMessage {
  errors?: Array<IMessage>;
  fieldKey: string;
}

const ErrorMessage = observer(({ errors, fieldKey }: IErrorMessage) => (
  <Fragment>
    {errors
      ?.filter(({ field }) => field === fieldKey)
      ?.map(({ message }) => (
        <p className="text-red-500" key={message}>
          {message}
        </p>
      ))}
  </Fragment>
));

export const Editor = observer(({ blogStore }: IEditor) => (
  <div className="">
    <form className="mx-20 pt-5 pb-10">
      <div className="pb-4">
        <label
          className="block text-gray-700 text-sm font-bold mb-2"
          htmlFor="title"
        >
          Title
        </label>
        <ErrorMessage errors={blogStore.errors} fieldKey="title" />
        <input
          name="title"
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          id="title"
          type="text"
          placeholder="Title"
          value={blogStore.title}
          onChange={(e) => blogStore.setField("title", e.target.value)}
        />
      </div>
      <div className="pb-4">
        <label
          className="block text-gray-700 text-sm font-bold mb-2"
          htmlFor="title"
        >
          Public
        </label>
        <input
          name="isPublic"
          className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          id="isPublic"
          type="checkbox"
          placeholder="isPublic"
          checked={blogStore.isPublic}
          onChange={blogStore.togglePublic}
        />
      </div>
      <div className="pb-4">
        <label
          className="block text-gray-700 text-sm font-bold mb-2"
          htmlFor="author"
        >
          Author
        </label>
        <ErrorMessage errors={blogStore.errors} fieldKey="author" />
        <input
          name="author"
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          id="author"
          type="text"
          placeholder="Author"
          value={blogStore.author}
          onChange={(e) => blogStore.setField("author", e.target.value)}
        />
      </div>
      <div className="pb-4">
        <label
          className="block text-gray-700 text-sm font-bold mb-2"
          htmlFor="content"
        >
          Content
        </label>
        <ErrorMessage errors={blogStore.errors} fieldKey="content" />
        <textarea
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          name="content"
          id=""
          cols={30}
          rows={10}
          value={blogStore.content}
          onChange={(e) => blogStore.setField("content", e.target.value)}
        ></textarea>
      </div>
    </form>
  </div>
));
