import React, { MouseEventHandler } from "react";

interface IModal {
  image: string;
  alt?: string;
  closeAction: MouseEventHandler<HTMLElement>;
}

export const ImageModal = ({ image, alt, closeAction }: IModal) => (
  <div
    className="min-w-screen h-screen animated fadeIn faster fixed left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover"
    id="modal-id"
  >
    <div
      className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
      onClick={closeAction}
      aria-hidden="true"
    />
    <div
      className="flex justify-center m-auto max-h-90vh w-auto max-w-full z-50"
      onClick={closeAction}
    >
      <button className="absolute top-3 right-3 bg-red-500 hover:bg-red-600 text-2xl w-10 h-10 rounded-full focus:outline-none text-white">
        X
      </button>
      <img
        className="object-scale-down px-5 max-h-3/4 h-auto w-auto"
        src={image}
        alt={alt}
      />
    </div>
  </div>
);
