import React from "react";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";
import { useUser } from "reactfire";
import { Blogs } from "./Blogs";
import { EditBlog } from "./Blogs/EditBlog";
import { NewBlog } from "./Blogs/NewBlog";
import { Galleries } from "./Galleries";
import { EditGallery } from "./Galleries/EditGallery";
import { NewGallery } from "./Galleries/NewGallery";
import { Login } from "./Login";
import { Index } from "./Index/index";

export const Admin = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <PrivateRoute exact path={path}>
        <Index />
      </PrivateRoute>
      <Route path={`${path}/login`}>
        <Login />
      </Route>
      <PrivateRoute exact path={`${path}/blogs`}>
        <Blogs />
      </PrivateRoute>
      <PrivateRoute path={`${path}/blogs/new`}>
        <NewBlog />
      </PrivateRoute>
      <PrivateRoute path={`${path}/blogs/edit/:blogId`}>
        <EditBlog />
      </PrivateRoute>
      <PrivateRoute exact path={`${path}/galleries`}>
        <Galleries />
      </PrivateRoute>
      <PrivateRoute exact path={`${path}/galleries/new`}>
        <NewGallery />
      </PrivateRoute>
      <PrivateRoute exact path={`${path}/galleries/edit/:galleryId`}>
        <EditGallery />
      </PrivateRoute>
    </Switch>
  );
};

const PrivateRoute = ({ children, ...rest }: any) => {
  const { data: user } = useUser();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/admin/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};
