import { cast, Instance, types } from 'mobx-state-tree'

const errorMessage = types.model("message", {
    field: types.string,
    message: types.string
})

export interface IMessage {
    field: string;
    message: string;
}

export const modes = {
    EDIT: "Edit",
    PREVIEW: "Preview",
};

export const BlogStore = types.model("BlogStore", {
    title: types.optional(types.string, ""),
    author: types.optional(types.string, ""),
    content: types.optional(types.string, ""),
    isPublic: types.optional(types.boolean, false),
    mode: types.optional(types.enumeration("mode", [modes.EDIT, modes.PREVIEW]), modes.EDIT),
    errors: types.array(errorMessage)
})
.views((self: any) => ({
    get isValid() {
        return (self.title && self.author && self.content)
    }
}))
.actions((self: any) => ({
    addErrorMessage(errorMessage: IMessage) {
        self.errors.push(errorMessage)
    },
    clearErrors() {
        self.errors = cast([])
    },
    changeMode() {
        self.mode = self.mode === modes.EDIT ? modes.PREVIEW : modes.EDIT;
    },
    togglePublic() {
        self.isPublic = !self.isPublic;
    },
    setField(key: string, value: string) {
        if (self.hasOwnProperty(key)) {
            self[key] = value
        }
    }
}))

export interface IBlogStore extends Instance<typeof BlogStore> {}