import React from "react";
import { aboutMe } from "../../components/AboutMe";
import { MarkDownViewer } from "../../components/MarkDownViewer";
import Selfie from "../../images/selfie.jpg";

export const About = () => (
  <div className="min-h-screen mb-10">
    <div className="mx-auto lg:px-48 px-5 mt-10">
      <div className="flex sm:flex-wrap justify-center">
        <div className="h-48 w-48 mb-10 items-center">
          <img
            className="rounded-lg border border-gray-100 shadow-sm w-auto"
            src={Selfie}
            alt="Joe Wilson with camera"
          />
        </div>
        <div>
          <MarkDownViewer content={aboutMe} />
        </div>
      </div>
    </div>
  </div>
);
