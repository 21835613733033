import { Observer, observer } from "mobx-react-lite";
import React, { Fragment, useEffect } from "react";
import { useStorage } from "reactfire";
import { GalleryModel } from "../../models/gallery";
import { Image } from "../Image";
import { LoadingSpinner } from "../LoadingSpinner";
import { ImageModal } from "../Modals/ImageModal";
import { GalleryStore } from "./GalleryStore";

interface GalleryViewer extends GalleryModel {
  setAsPreview?: Function;
}

export const Gallery = observer(({ id, setAsPreview }: GalleryViewer) => {
  const storage = useStorage();
  const galleryRef = storage.ref(`gallery-${id}`);
  const galleryStore = GalleryStore.create();

  useEffect(() => {
    galleryStore.setImagesFromList(galleryRef);
  }, [galleryRef, galleryStore]);

  return (
    <Observer>
      {() => (
        <Fragment>
          {galleryStore.loading && <LoadingSpinner />}
          <div className="mx-auto container">
            <div className="grid grid-flow-row grid-cols-1 p-4 md:p-20 md:grid-cols-3 md:gap-2 space-y-2 sm:space-y-0 sm:gap-3">
              {galleryStore.images.map((image) => (
                <Image
                  key={image}
                  viewAction={() => galleryStore.openImageViewer(image)}
                  src={image}
                  alt="gallery image"
                />
              ))}
            </div>
          </div>
          {galleryStore.selectedImage && !setAsPreview && (
            <ImageModal
              closeAction={galleryStore.closeImageViewer}
              image={galleryStore.selectedImage}
            />
          )}
        </Fragment>
      )}
    </Observer>
  );
});
