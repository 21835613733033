import React, { MouseEventHandler } from "react";

interface IImage {
  src: string;
  alt: string;
  viewAction?: MouseEventHandler<HTMLDivElement>;
}

export const Image = ({ src, alt, viewAction }: IImage) => {
  const imageRef = React.useRef<HTMLImageElement>(null);
  const [shouldTakeTwoRows, setTakeTwoRows] = React.useState(false);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const height = imageRef.current?.height || 0;
    const width = imageRef.current?.width || 0;
    console.log(imageRef.current?.src, height, width);
    if (height > width) {
      setTakeTwoRows(true);
    }
  }, [src, shouldTakeTwoRows, loading]);
  return (
    <div
      className={`w-full rounded${shouldTakeTwoRows ? " row-span-2" : ""}`}
      onClick={viewAction}
    >
      <img
        ref={imageRef}
        className="blur-md invert shadow-l cursor-pointer"
        onLoad={() => setLoading(false)}
        src={src}
        alt={alt}
      />
    </div>
  );
};
