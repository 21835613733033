import React from "react";
import { Link } from "react-router-dom";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import { GalleryModel } from "../../../models/gallery";

const Row = ({ id, title, isPublic }: GalleryModel) => {
  const collection = useFirestore().collection("galleries");
  const remove = () => {
    collection
      .doc(id)
      .delete()
      .then(() => {
        console.log("success", id, "removed");
      })
      .catch((e) => {
        console.error("failure to delete file", e);
      });
  };

  return (
    <tr>
      <td className="px-6 py-4 whitespace-nowrap">{title}</td>
      <td className="px-6 py-4 whitespace-nowrap">{isPublic ? "Yes" : "No"}</td>
      <td className="px-6 py-4 whitespace-nowrap">
        <Link to={`/admin/galleries/edit/${id}`}>
          <span className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            <svg
              className="-ml-1 mr-2 h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
            </svg>
            Edit
          </span>
        </Link>
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        <button onClick={remove}>
          <span className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="-ml-1 mr-2 h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 13h6m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
              />
            </svg>
            Delete
          </span>
        </button>
      </td>
    </tr>
  );
};

export const Galleries = () => {
  const galRef = useFirestore().collection("galleries");
  const { data: blogs } = useFirestoreCollectionData(galRef);
  const blogArray = blogs?.map((blog: any) => {
    return <Row key={blog.id} {...blog} />;
  });

  return (
    <React.Fragment>
      <div className="lg:flex lg:items-center lg:justify-between">
        <div className="flex-1 min-w-0 p-5">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
            Galleries Console
          </h2>
        </div>
      </div>
      <div className="bg-gray-100 px-5 lg:px-32 py-10 h-auto">
        <div className="bg-white shadow overflow-x-scroll sm:rounded-lg">
          <div className="border-t border-gray-200">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Title
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Public
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Actions
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  ></th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {blogArray}
                <tr>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <Link to="/admin/galleries/new">
                      <span className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        Create New
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="ml-5 h-5 w-5"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M9 13h6m-3-3v6m5 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                          />
                        </svg>
                      </span>
                    </Link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
