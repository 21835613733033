import React from "react";
import { Link } from "react-router-dom";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import { GalleryCard } from "../../components/ImageCard";

interface IGalleryItem {
  title: string;
  image: string;
  id: string;
}

const GalleryItem = ({ title, image, id }: IGalleryItem) => (
  <GalleryCard title={title} id={id}>
    <img className="w-full" src={image} alt="title" />
  </GalleryCard>
);

export const GallerySection = () => {
  const placeholder = "https://via.placeholder.com/350x150";

  const galleriesRef = useFirestore().collection("galleries");
  const query = galleriesRef.orderBy("date", "desc").limit(3);
  const { data: galleries } = useFirestoreCollectionData(query);

  return (
    <div className="mt-20 relative bg-white overflow-hidden">
      <main className="mx-auto">
        <div className="px-8 pb-5 text-center">
          <h1 className="text-2xl tracking-tight font-extrabold text-gray-900 sm:text-3xl md:text-4xl">
            My Latest Adventures
          </h1>
        </div>
        <div className="flex flex-wrap flex-row justify-center">
          {galleries
            ?.filter((gallery: any) => gallery.isPublic)
            .map((gallery: any) => (
              <GalleryItem
                key={gallery.id}
                title={gallery.title}
                image={gallery.previewImage || placeholder}
                id={gallery.id}
              />
            ))}
        </div>
        <div className="px-8 py-5 text-center">
          <Link
            className="inline-flex items-center px-4 py-2 rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            to="/galleries"
          >
            See all my adventures here!
          </Link>
        </div>
      </main>
    </div>
  );
};
