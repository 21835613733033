import React from "react";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import { BlogCard } from "../../components/BlogCard";

export const Blogs = () => {
  const blogsRef = useFirestore().collection("blogs");
  const query = blogsRef.orderBy("date", "desc").limit(9);
  const { data: blogs } = useFirestoreCollectionData(query);

  return (
    <React.Fragment>
      <div className="min-h-90vh">
        <div className="flex flex-wrap flex-col items-center py-10 h-48">
          <h1 className="text-4xl font-bold">The Blog Space</h1>
          <h3 className="text-md font-bold py-5">Subheading here</h3>
        </div>
        <div className="flex flex-wrap flex-row justify-center pb-10">
          {blogs
            ?.filter((blog) => blog.isPublic)
            .map((blog: any) => {
              return <BlogCard key={blog.id} {...blog} />;
            })}
        </div>
      </div>
    </React.Fragment>
  );
};
