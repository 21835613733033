import React from "react";
import ReactMarkdown from "react-markdown";
import { H1, H2, H3, Link, OL, UL, P } from "./MarkDownRenderers";

interface IMarkDownViewer {
  content: string;
}

export const MarkDownViewer = ({ content }: IMarkDownViewer) => (
  <ReactMarkdown
    className="mx-5 lg:mx-20 xs:pt-5 pb-10"
    components={{
      h1: H1,
      h2: H2,
      h3: H3,
      a: Link,
      ul: UL,
      ol: OL,
      p: P,
    }}
  >
    {content}
  </ReactMarkdown>
);
