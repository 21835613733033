import React from 'react'

export const ConfigContext = React.createContext({})

export interface Config {
    features?: Features
}

export interface Features {
    blogEnabled: boolean
    galleryEnabled: boolean
    aboutEnabled: boolean
    contactEnabled: boolean
    homeEnabled: boolean
    [feature: string]: boolean
}