import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useFirestore } from "reactfire";
import { v4 as uuidv4 } from "uuid";
import { GalleryModel } from "../../../models/gallery";

export const NewGallery = () => {
  const history = useHistory();
  const collection = useFirestore().collection("galleries");

  const galleryId = uuidv4();

  const gallery: GalleryModel = {
    title: "",
    id: galleryId,
    previewImage: "",
    isPublic: false,
  };

  const [state, setState] = useState(gallery);

  const updateField = (key: string) => {
    return (event: any) => {
      setState({
        ...state,
        [key]: event.target.value,
      });
    };
  };

  const onPublicChange = () => {
    setState({
      ...state,
      isPublic: !state.isPublic,
    });
  };

  const save = () => {
    collection.doc(state.id).set({
      ...state,
      date: Date.now(),
    });
    history.push("/admin/galleries");
  };

  return (
    <React.Fragment>
      <div className="lg:flex lg:items-center lg:justify-between bg-white shadow p-20 pt-5 pb-5 min-w-0">
        <div className="flex-1">
          <h2 className="text-lg">Create New Gallery</h2>
        </div>
        <div className="flex-1 flex items-center justify-end sm:items-stretch sm:justify-end">
          <button className="mx-1" onClick={save}>
            <span className="inline-flex items-center px-4 py-2 rounded-md shadow-sm text-sm font-medium text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              Save
            </span>
          </button>
        </div>
      </div>
      <div className="flex flex-row">
        <div className="flex-auto">
          <form className="mx-20 pt-5 pb-10">
            <div className="pb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="title"
              >
                Title
              </label>
              <input
                name="title"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="title"
                type="text"
                placeholder="Title"
                value={state.title}
                onChange={updateField("title")}
              />
            </div>
            <div className="pb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="title"
              >
                Public
              </label>
              <input
                name="isPublic"
                className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="isPublic"
                type="checkbox"
                placeholder="isPublic"
                checked={state.isPublic}
                onChange={onPublicChange}
              />
            </div>
          </form>
          <div></div>
        </div>
      </div>
    </React.Fragment>
  );
};
