import React from "react";
import Background from "../../images/herobg1.jpg";

export const Hero = () => (
  <div className="relative bg-white h-64 overflow-hidden ">
    <div className="max-w-7xl mx-auto min-h-full">
      <div className="relative z-10 bg-white lg:max-w-2xl lg:w-1/2 h-64">
        <svg
          className="hidden lg:block absolute right-0 inset-y-0 h-full w-24 text-white transform translate-x-1/2"
          fill="currentColor"
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
          aria-hidden="true"
        >
          <polygon points="50,0 100,0 50,100 0,100" />
        </svg>
        <div className="relative pt-6 px-4 sm:px-6 lg:px-8" />
        <main className="mt-10 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="sm:text-center lg:text-left">
            <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
              <span className="block xl:inline">The Rolling</span>
              <span className="block text-blue-600 xl:inline">Shutter</span>
            </h1>
            <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
              Automotive and Landscape Photography
            </p>
          </div>
        </main>
      </div>
    </div>
    <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
      <img
        className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full -10"
        src={Background}
        alt="hero background"
      />
    </div>
  </div>
);
