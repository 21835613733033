import React from "react";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import { BlogCard } from "../../components/BlogCard";

interface IBlog {
  title: String;
}

export const BlogSection = ({ title }: IBlog) => {
  const blogsRef = useFirestore().collection("blogs");
  const query = blogsRef.orderBy("date", "desc").limit(9);
  const { data: blogs } = useFirestoreCollectionData(query);

  return (
    <div className="mt-10 relative bg-white overflow-hidden">
      <main className="mx-auto">
        <div className="px-8 text-center">
          <h1 className="text-2xl tracking-tight font-extrabold text-gray-900 sm:text-3xl md:text-4xl">
            {title}
          </h1>
        </div>
        <div className="flex flex-wrap flex-row justify-center">
          {blogs
            ?.filter((blog: any) => blog.isPublic)
            .map((blog: any) => {
              return <BlogCard key={blog.id} {...blog} />;
            })}
        </div>
      </main>
    </div>
  );
};
