import { Observer, observer } from "mobx-react-lite";
import React, { Fragment, useEffect } from "react";
import { useStorage } from "reactfire";
import { GalleryModel } from "../../../../models/gallery";
import { ImageCard } from "../../../../components/ImageCard";
import { LoadingSpinner } from "../../../../components/LoadingSpinner";
import { ImageModal } from "../../../../components/Modals/ImageModal";
import { GalleryStore } from "./GalleryStore";

interface GalleryViewer extends GalleryModel {
  setAsPreview?: Function;
}

export const Gallery = observer(({ id, setAsPreview }: GalleryViewer) => {
  const storage = useStorage();
  const galleryRef = storage.ref(`gallery-${id}`);
  const galleryStore = GalleryStore.create();

  useEffect(() => {
    galleryStore.setImagesFromList(galleryRef);
  }, [galleryRef, galleryStore]);

  const setImageAsPreview = () => {
    if (setAsPreview) {
      setAsPreview(galleryStore.selectedImage);
      console.log("preview set:", galleryStore.selectedImage);
    }
  };

  return (
    <Observer>
      {() => (
        <Fragment>
          {galleryStore.loading && <LoadingSpinner />}
          <div className="flex flex-wrap flex-row justify-center pb-10">
            {galleryStore.images?.map((image) => (
              <ImageCard
                key={image}
                link={false}
                viewAction={() => galleryStore.openImageViewer(image)}
                isSelected={galleryStore.selectedImage === image}
                isSelectable={!!setAsPreview}
                setAsPreview={setImageAsPreview}
              >
                <img
                  className="m-auto w-full"
                  src={image}
                  alt="gallery media"
                />
              </ImageCard>
            ))}
          </div>
          {galleryStore.selectedImage && !setAsPreview && (
            <ImageModal
              closeAction={galleryStore.closeImageViewer}
              image={galleryStore.selectedImage}
            />
          )}
        </Fragment>
      )}
    </Observer>
  );
});
