import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useFirestore, useFirestoreDocData } from "reactfire";
import { ImageAssetAdder } from "../../../components/AssetAdder/Image";
import { PreviewImage } from "../../../components/AssetPicker/PreviewImage";
import { Gallery } from "./Gallery";

export const EditGallery = () => {
  const history = useHistory();
  const collection = useFirestore().collection("galleries");
  const { galleryId }: any = useParams();
  const galleryRef: any = collection.doc(galleryId);
  const { data: gallery }: any = useFirestoreDocData(galleryRef);

  const [state, setState] = useState({
    title: gallery?.title,
    isPublic: gallery?.isPublic,
    id: gallery?.id,
    previewImage: gallery?.previewImage,
  });

  useEffect(() => {
    setState({
      title: gallery?.title,
      isPublic: gallery?.isPublic,
      id: gallery?.id,
      previewImage: gallery?.previewImage,
      ...gallery,
    });
  }, [gallery]);

  const onPublicChange = () => {
    setState({
      ...state,
      isPublic: !state.isPublic,
    });
  };

  const setAsPreview = (image: string) => {
    setState({
      ...state,
      previewImage: image,
    });
    console.log(state);
  };

  const save = () => {
    console.log(state);
    collection.doc(state.id).update({
      ...state,
    });
    history.push("/admin/galleries");
  };

  return (
    <React.Fragment>
      <div className="lg:flex lg:items-center lg:justify-between bg-white shadow p-20 pt-5 pb-5 min-w-0">
        <div className="flex-1">
          <h2 className="text-lg">Edit {state.title}</h2>
        </div>
        <div className="flex-1 flex items-center justify-end sm:items-stretch sm:justify-end">
          <button className="mx-1" onClick={save}>
            <span className="inline-flex items-center px-4 py-2 rounded-md shadow-sm text-sm font-medium text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              Save
            </span>
          </button>
        </div>
      </div>
      <div className="flex flex-row">
        <div className="flex-auto">
          <form className="mx-20 pt-5 pb-10">
            <div className="pb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="title"
              >
                Public
              </label>
              <input
                name="isPublic"
                className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="isPublic"
                type="checkbox"
                placeholder="isPublic"
                checked={state.isPublic}
                onChange={onPublicChange}
              />
            </div>
          </form>
          <div>
            {gallery?.id && (
              <Gallery {...gallery} setAsPreview={setAsPreview} />
            )}
          </div>
        </div>
        <div className="flex-1">
          <PreviewImage image={state.previewImage} />
          <ImageAssetAdder galleryId={galleryId} />
        </div>
      </div>
    </React.Fragment>
  );
};
