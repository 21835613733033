import React, { useRef, useState } from "react";
import "firebase/storage";
import { useFirestore, useFirestoreDocDataOnce, useStorage } from "reactfire";
import { LoadingSpinner } from "../LoadingSpinner";

interface IImageAssetAdder {
  galleryId: string;
}

export const ImageAssetAdder = ({ galleryId }: IImageAssetAdder) => {
  const [uploadTask, setUploadTask] = useState<
    firebase.default.storage.UploadTask | undefined
  >(undefined);
  const [fileList, setFileList] = useState<any>(undefined);
  const storage = useStorage();

  const collection = useFirestore().collection("galleries");
  const galleryRef: any = collection.doc(galleryId);
  const { data: gallery }: any = useFirestoreDocDataOnce(galleryRef);

  const formRef = useRef<HTMLFormElement>(null);

  const updateFileRef = (event: any) => {
    setFileList(event.target.files);
  };

  const upload = () => {
    // upload to storage
    const fileToUpload = fileList[0];
    if (!fileToUpload) {
      return;
    }
    console.log("uploading file");
    const fileName = fileToUpload.name;
    const fileRef = storage.ref(`gallery-${galleryId}`).child(fileName);
    const task = fileRef?.put(fileToUpload);
    task?.then((item) => {
      console.log("upload complete");
      setUploadTask(undefined);
      formRef.current?.reset();
      const galleryImages = gallery.images ? [...gallery.images] : [];
      if (gallery.images) {
        collection.doc(galleryId).update({
          images: galleryImages,
          date: Date.now(),
        });
      }
    });
    setUploadTask(task);
  };

  return (
    <React.Fragment>
      <div className="relative m-5 bg-white px-10 pt-5 pb-5 rounded-md border-2 shadow-md">
        <div className="text-center border-b-2">
          <h1 className="text-l tracking-tight font-extrabold text-gray-900">
            Add Image Asset
          </h1>
        </div>
        <div className="mt-5">
          <form ref={formRef}>
            <label htmlFor="avatar">Choose an image to upload:</label>

            <input
              type="file"
              id="avatar"
              name="avatar"
              accept="image/png, image/jpeg"
              onChange={updateFileRef}
            />
          </form>
        </div>
        <div className="mt-5 pt-2 border-t-2">
          <button className="mx-1" onClick={upload}>
            <span className="inline-flex items-center px-4 py-2 rounded-md shadow-sm text-sm font-medium text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              Upload Image
            </span>
          </button>
        </div>
      </div>
      {uploadTask && <LoadingSpinner />}
    </React.Fragment>
  );
};
