import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { useUser } from "reactfire";
import { Footer } from "./components/Footer";
import { Navbar } from "./components/Navbar";
import { UserContext } from "./components/userContext";
import { About } from "./pages/About";
import { Admin } from "./pages/Admin";
import { Contact } from "./pages/Contact";
import { Home } from "./pages/Home";
import config from "./fireblog.config.json";
import { ConfigContext } from "./components/configContext";
import { Blog } from "./pages/Blog";
import { Blogs } from "./pages/Blogs";
import { Galleries } from "./pages/Galleries";
import { GalleryPage } from "./pages/Gallery";

function App() {
  const user = useUser();

  return (
    <UserContext.Provider value={user}>
      <ConfigContext.Provider value={config}>
        <Router>
          <Navbar />
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/about">
              <About />
            </Route>
            <Route path="/contact">
              <Contact />
            </Route>
            <Route path="/admin">
              <Admin />
            </Route>
            <FeatureRoute
              exact
              path="/blog"
              feature={config.features?.blogEnabled}
            >
              <Blogs />
            </FeatureRoute>
            <FeatureRoute
              feature={config.features?.blogEnabled}
              path="/blog/:blogId"
            >
              <Blog />
            </FeatureRoute>
            <FeatureRoute
              exact
              path="/galleries"
              feature={config.features?.galleryEnabled}
            >
              <Galleries />
            </FeatureRoute>
            <FeatureRoute
              exact
              path="/gallery/:id"
              feature={config.features?.galleryEnabled}
            >
              <GalleryPage />
            </FeatureRoute>
          </Switch>
          <Footer />
        </Router>
      </ConfigContext.Provider>
    </UserContext.Provider>
  );
}

const FeatureRoute = ({ feature, children, ...rest }) => (
  <Route
    {...rest}
    render={({ location }) =>
      feature ? (
        children
      ) : (
        <Redirect
          to={{
            pathname: "/",
            state: { from: location },
          }}
        />
      )
    }
  />
);

export default App;
