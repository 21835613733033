import React, { Fragment, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useAuth } from "reactfire";
import { Config, ConfigContext } from "./configContext";
import { FeatureEnabledLink } from "./FeatureEnabledLink";
import { UserContext } from "./userContext";
import Logo from "../images/logo.png";

export const Navbar = () => {
  const auth = useAuth();
  const history = useHistory();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const logout = async () => {
    await auth.signOut();
    history.push("/admin/login");
  };

  return (
    <UserContext.Consumer>
      {(user: any) => {
        const { data } = user;
        return (
          <ConfigContext.Consumer>
            {(config: Config) => (
              <nav className="bg-gray-800">
                <div className="mx-auto px-2 sm:px-6 lg:px-8">
                  <div className="relative flex items-center justify-between h-16">
                    <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                      <button
                        type="button"
                        onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                        className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        aria-controls="mobile-menu"
                        aria-expanded="false"
                      >
                        <span className="sr-only">Open main menu</span>

                        <svg
                          className="block h-6 w-6"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M4 6h16M4 12h16M4 18h16"
                          />
                        </svg>
                        <svg
                          className="hidden h-6 w-6"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </button>
                    </div>
                    <div className="absolute right-0 flex justify-end sm:hidden">
                      {/*  eslint-disable-next-line jsx-a11y/img-redundant-alt*/}
                      <img
                        className="block h-10 w-full justify-end"
                        src={Logo}
                        alt="Photo Joe"
                      />
                    </div>
                    <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                      <div className="hidden sm:block sm:ml-6">
                        <div className="flex space-x-4">
                          <Link className="min-w-max" to="/">
                            {/*  eslint-disable-next-line jsx-a11y/img-redundant-alt*/}
                            <img
                              className="block h-10 w-full justify-end"
                              src={Logo}
                              alt="Photo Joe"
                            />
                          </Link>

                          <FeatureEnabledLink
                            classes="text-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                            feature="aboutEnabled"
                            href="/about"
                          >
                            About
                          </FeatureEnabledLink>

                          <FeatureEnabledLink
                            feature="contactEnabled"
                            href="/contact"
                            classes="text-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                          >
                            Contact
                          </FeatureEnabledLink>
                          <FeatureEnabledLink
                            feature="blogEnabled"
                            href="/blog"
                            classes="text-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                          >
                            Blog
                          </FeatureEnabledLink>
                          <FeatureEnabledLink
                            feature="galleryEnabled"
                            href="/galleries"
                            classes="text-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                          >
                            Galleries
                          </FeatureEnabledLink>
                        </div>
                      </div>
                    </div>
                    {data && (
                      <div className="flex-1 sm:flex items-center justify-end sm:items-stretch sm:justify-end hidden">
                        <Link
                          to="/admin"
                          className="text-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                        >
                          Console
                        </Link>
                        <button
                          className="text-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                          onClick={logout}
                        >
                          Logout
                        </button>
                      </div>
                    )}
                  </div>
                </div>

                <div
                  className={`${mobileMenuOpen ? "" : "hidden"} sm:hidden`}
                  id="mobile-menu"
                >
                  <div className="flex flex-col">
                    <Link
                      onClick={() => setMobileMenuOpen(false)}
                      to="/"
                      className="text-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 hover:text-white rounded-md text-sm font-medium"
                    >
                      <div className="flex-1 w-full px-5 py-5">Home</div>
                    </Link>
                    <FeatureEnabledLink
                      onClick={() => setMobileMenuOpen(false)}
                      href="/about"
                      feature="aboutEnabled"
                      classes="text-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 hover:text-white rounded-md text-sm font-medium"
                    >
                      <div className="flex-1 w-full px-5 py-5">About</div>
                    </FeatureEnabledLink>
                    <FeatureEnabledLink
                      onClick={() => setMobileMenuOpen(false)}
                      href="/contact"
                      feature="contactEnabled"
                      classes="text-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 hover:text-white rounded-md text-sm font-medium"
                    >
                      <div className="flex-1 w-full px-5 py-5">Contact</div>
                    </FeatureEnabledLink>
                    <FeatureEnabledLink
                      onClick={() => setMobileMenuOpen(false)}
                      feature="blogEnabled"
                      href="/blog"
                      classes="text-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 hover:text-white rounded-md text-sm font-medium"
                    >
                      <div className="flex-1 w-full px-5 py-5">Blog</div>
                    </FeatureEnabledLink>
                    <FeatureEnabledLink
                      onClick={() => setMobileMenuOpen(false)}
                      feature="galleryEnabled"
                      href="/galleries"
                      classes="text-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 hover:text-white rounded-md text-sm font-medium"
                    >
                      <div className="flex-1 w-full px-5 py-5">Galleries</div>
                    </FeatureEnabledLink>
                    {data && (
                      <Fragment>
                        <Link
                          onClick={() => setMobileMenuOpen(false)}
                          to="/admin"
                          className="text-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 hover:text-white rounded-md text-sm font-medium"
                        >
                          <div className="flex-1 w-full px-5 py-5">Console</div>
                        </Link>

                        <button
                          className="text-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 hover:text-white rounded-md text-sm font-medium"
                          onClick={logout}
                        >
                          <div className="flex-1 w-full px-5 py-5 text-left">
                            Logout
                          </div>
                        </button>
                      </Fragment>
                    )}
                  </div>
                </div>
              </nav>
            )}
          </ConfigContext.Consumer>
        );
      }}
    </UserContext.Consumer>
  );
};
