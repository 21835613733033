import React from "react";

interface IPreviewImage {
  image: string;
}

export const PreviewImage = ({ image }: IPreviewImage) => (
  <div className="m-5 bg-white px-10 pt-5 pb-5 rounded-md border-2 shadow-md">
    <div className="text-center border-b-2">
      <h1 className="text-l tracking-tight font-extrabold text-gray-900">
        Gallery Preview
      </h1>
    </div>
    <div className="mt-5">
      <img className="m-auto" src={image} alt="preview" />
    </div>
  </div>
);
