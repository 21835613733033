import React from "react";
import ReactMarkdown from "react-markdown";
import { Link } from "react-router-dom";
import { BlogModel } from "../models/blog";

export const BlogCard = ({ title, content, author, date, id }: BlogModel) => (
  <div className="relative bg-white px-10 py-5 m-5 w-full lg:w-1/4 rounded-md border-2 shadow-md">
    <div className="text-center border-b-2">
      <h1 className="text-l tracking-tight font-extrabold text-gray-900">
        {title}
      </h1>
      <h3 className="text-sm">
        By {author} on {new Date(date).toLocaleString().split(",")[0]}
      </h3>
    </div>
    <div className="mt-5 max-h-24 h-24 overflow-hidden">
      <ReactMarkdown>
        {content.slice(0, 50).replace("\n", " ") + "..."}
      </ReactMarkdown>
    </div>
    <div className="mt-5 pt-2 border-t-2 text-center">
      <Link to={`/blog/${id}`}>Go To Blog</Link>
    </div>
  </div>
);
