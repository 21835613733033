import React from "react";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import { GalleryCard } from "../../components/ImageCard";

interface IGalleryItem {
  title: string;
  image: string;
  id: string;
}

const GalleryItem = ({ title, image, id }: IGalleryItem) => (
  <GalleryCard title={title} id={id}>
    <img className="w-full" src={image} alt="title" />
  </GalleryCard>
);

export const Galleries = () => {
  const placeholder = "https://via.placeholder.com/350x150";

  const galleriesRef = useFirestore().collection("galleries");
  const query = galleriesRef.orderBy("date", "desc").limit(9);
  const { data: galleries } = useFirestoreCollectionData(query);

  return (
    <div>
      <div className="flex flex-wrap flex-col items-center py-10">
        <h1 className="text-4xl font-bold">Galleries</h1>
      </div>
      <div className="flex flex-wrap flex-row justify-center pb-10">
        {galleries
          ?.filter((gallery: any) => gallery.isPublic)
          .map((gallery: any) => (
            <GalleryItem
              key={gallery.id}
              title={gallery.title}
              image={gallery.previewImage || placeholder}
              id={gallery.id}
            />
          ))}
      </div>
    </div>
  );
};
