import React from "react";

export const Form = () => (
  <React.Fragment>
    <div className="lg:mt-10 mt-0 ">
      <div className="md:grid md:grid-cols-3 md:gap-6">
        <div className="md:col-span-1">
          <div className="px-4 sm:px-0">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Reach out to me
            </h3>
            <p className="mt-1 text-sm text-gray-600">
              I'd love to hear from you! Please let me know what I can do to
              help by filling out the form on this page.
            </p>
          </div>
        </div>
        <div className="mt-5 md:mt-0 md:col-span-2">
          <iframe
            className="w-full overflow-hidden"
            title="contactForm"
            src="https://docs.google.com/forms/d/e/1FAIpQLSeoTI62cXwVOLeEwBiR-STfrNIm54hNWrW99m9sCwbJv6I7Zg/viewform?embedded=true"
            scrolling="no"
            height="900"
          >
            Loading…
          </iframe>
        </div>
      </div>
    </div>
  </React.Fragment>
);
