import * as React from "react";

export const LoadingSpinner = () => {
  return (
    <div
      className="fixed z-10 inset-0 overflow-y-auto"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
      >
        <div className="animate-pulse mt-60 z-100 bg-white border border-light-blue-300 shadow rounded-md p-4 max-w-sm w-full mx-auto text-center">
          <span className="text-lg">Loading...</span>
        </div>
      </div>
    </div>
  );
};
