import { cast, types, flow } from 'mobx-state-tree'

export const GalleryStore = types.model("GalleryModel", {
    loading: types.optional(types.boolean, true),
    images: types.array(types.string),
    modalOpen: types.optional(types.boolean, false),
    selectedImage: types.maybe(types.string)
})
.actions((self: any) => ({
    setImages(images: Array<string>) {
        self.images = cast(images)
    },
    addImage(image: string) {
        self.images.push(image)
    },
    setImagesFromList: flow(function* setImagesFromList(
        galleryRef: firebase.default.storage.Reference
      ) {
        const list: firebase.default.storage.ListResult = yield galleryRef.listAll();
        const itemUrls: any = list.items.map(async (item) => {
          const itemUrl =  item.getDownloadURL();
          return itemUrl;
        });
        const images = yield Promise.all(itemUrls);
        self.setImages(images);
        self.loading = false;
    }),
    openImageViewer(selectedImage: string) {
        self.selectedImage = selectedImage
    },
    closeImageViewer() {
        self.selectedImage = undefined
    }
}))