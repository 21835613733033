import React, { MouseEventHandler } from "react";
import { Link } from "react-router-dom";

interface ICard {
  title?: string;
  children: any;
  id?: string;
  link?: boolean;
  isSelected?: boolean;
  isSelectable?: boolean;
  setAsPreview?: MouseEventHandler<HTMLElement>;
  viewAction?: MouseEventHandler<HTMLDivElement>;
}

export const GalleryCard = ({ title, children, id, link = true }: ICard) => (
  <div className="relative bg-white px-10 pt-5 pb-5 m-5 w-full lg:w-1/4 rounded-md border-2 shadow-md">
    <Link to={`/gallery/${id}`}>
      {title && (
        <div className="text-center border-b-2">
          <h1 className="text-l tracking-tight font-extrabold text-gray-900">
            {title}
          </h1>
        </div>
      )}
      <div className="m-5">{children}</div>
      <div className="mt-5 text-center pt-2 border-t-2">View Gallery</div>
    </Link>
  </div>
);

export const ImageCard = ({
  title,
  children,
  id,
  link = true,
  viewAction,
  setAsPreview,
  isSelected,
  isSelectable,
}: ICard) => {
  const imageClick = () => {
    if (!isSelected) {
      return viewAction;
    }
  };

  return (
    <div
      className={`bg-white px-2 py-10 m-5 w-full lg:w-1/4 rounded-md border-2 shadow-md ${
        isSelectable ? "hover:bg-indigo-300" : ""
      } ${isSelected ? "bg-indigo-300" : "cursor-pointer"}`}
      onClick={imageClick()}
    >
      {title && (
        <div className="text-center border-b-2">
          <h1 className="text-l tracking-tight font-extrabold text-gray-900">
            {title}
          </h1>
        </div>
      )}
      <div className="m-auto h-full flex">{children}</div>
      {isSelected && isSelectable && (
        <div className="text-center p-2 border-t-2">
          <button onClick={setAsPreview}>Set as Preview</button>
        </div>
      )}
    </div>
  );
};
