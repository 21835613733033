import React from "react";
import { useParams } from "react-router-dom";
import { useFirestore, useFirestoreDocDataOnce } from "reactfire";
import { MarkDownViewer } from "../../components/MarkDownViewer";

export const Blog = () => {
  const collection = useFirestore().collection("blogs");
  const { blogId }: any = useParams();
  const blogRef: any = collection.doc(blogId);
  const { data: blog }: any = useFirestoreDocDataOnce(blogRef);

  return (
    <React.Fragment>
      <div className="flex flex-grow bg-white shadow p-5 lg:px-20 pb-20 w-full">
        <div className="flex-1 shadow appearance-none border rounded py-2 lg:px-3 lg:mx-36 my-10">
          <div className="mx-20 pt-5 text-center">
            <h1 className="text-4xl  font-bold">{blog?.title}</h1>
            <h3 className="text-md font-bold pt-5">
              By {blog?.author} on{" "}
              {new Date(blog?.date).toLocaleString().split(",")[0]}
            </h3>
          </div>
          <div className="mt-5 mx-32 pt-5 border-t-2" />
          <MarkDownViewer content={blog?.content} />
        </div>
      </div>
    </React.Fragment>
  );
};
