import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useFirestore, useFirestoreDocDataOnce } from "reactfire";
import { Gallery } from "../../components/Gallery";

export const GalleryPage = () => {
  const collection = useFirestore().collection("galleries");
  const { id }: any = useParams();
  const galleryRef: any = collection.doc(id);
  const { data: gallery }: any = useFirestoreDocDataOnce(galleryRef);

  useEffect(() => {
    const preventDefault = (e: any) => {
      e.preventDefault();
    };
    document.addEventListener("contextmenu", preventDefault);

    return function cleanup() {
      document.removeEventListener("contextmenu", preventDefault);
    };
  }, [gallery]);

  return (
    <div className="w-full">
      <div className="flex flex-wrap flex-col items-center py-10">
        <h1 className="text-4xl font-bold">{gallery?.title}</h1>
      </div>
      <div className="flex flex-wrap flex-row justify-center py-10">
        {gallery?.id && <Gallery {...gallery} />}
      </div>
    </div>
  );
};
