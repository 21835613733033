import React from 'react'

export const Link = (props) => {
    return <a href={props.href} className="text-indigo-600 hover:text-indigo-400">{props.children}</a>
}

export const BlockQuote = (props) => {
    return <blockquote className="my-block-quote">{props.children}</blockquote>
}

export const H1 = (props) => {
    return <h1 className="text-2xl tracking-tight font-extrabold text-gray-900 pb-5">{props.children}</h1>
}

export const H2 = (props) => {
    return <h2 className="text-lg tracking-tight font-extrabold text-gray-900 pb-5">{props.children}</h2>
}

export const H3 = (props) => {
    return <h3 className="text-md tracking-tight font-extrabold text-gray-900 pb-5">{props.children}</h3>
}

export const UL = props => {
    return <ul className="list-disc mb-5 px-10">{props.children}</ul>
}

export const OL = props => {
    return <ol className="list-decimal mb-5 px-10">{props.children}</ol>
}

export const P = props => {
    return <p className="mb-10">{props.children}</p>
}