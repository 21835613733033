import React, { EventHandler } from "react";
import { Link } from "react-router-dom";
import { Config, ConfigContext } from "./configContext";

interface IFeatureEnabledLink {
  onClick?: EventHandler<any>;
  feature: string;
  href: string;
  classes?: string;
  children: any;
}

export const FeatureEnabledLink = ({
  onClick,
  feature,
  href,
  classes,
  children,
}: IFeatureEnabledLink) => {
  return (
    <ConfigContext.Consumer>
      {(config: Config) => (
        <React.Fragment>
          {config.features && config.features[feature] && (
            <Link onClick={onClick} className={classes} to={href}>
              {children}
            </Link>
          )}
        </React.Fragment>
      )}
    </ConfigContext.Consumer>
  );
};
