import React from "react";
import ReactDOM from "react-dom";
import "./styles/index.css";
import App from "./App";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import reportWebVitals from "./reportWebVitals";
import { FirebaseAppProvider } from "reactfire";

const firebaseConfig = {
  apiKey: "AIzaSyC5BBrWFrDPnUJTlwXZigheZpaZal27YWU",
  authDomain: "photojoe-site.firebaseapp.com",
  projectId: "photojoe-site",
  storageBucket: "photojoe-site.appspot.com",
  messagingSenderId: "638597336221",
  appId: "1:638597336221:web:0cdc1d6b48007a01351874"
};


ReactDOM.render(
  <React.StrictMode>
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <App />
    </FirebaseAppProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
