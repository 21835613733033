import { Observer } from "mobx-react-lite";
import React from "react";
import { useHistory } from "react-router-dom";
import { useFirestore } from "reactfire";
import { v4 as uuidv4 } from "uuid";
import { Editor } from "../../../../components/Editor";
import { MarkDownViewer } from "../../../../components/MarkDownViewer";
import { BlogStore, modes } from "../BlogStore";

export const NewBlog = () => {
  const history = useHistory();
  const collection = useFirestore().collection("blogs");

  const blogStore = BlogStore.create();

  const save = () => {
    if (blogStore.isValid) {
      const id = uuidv4();
      collection.doc(id).set({
        id,
        title: blogStore.title,
        author: blogStore.author,
        content: blogStore.content,
        isPublic: blogStore.isPublic,
        date: Date.now(),
      });
      history.push("/admin/blogs");
    } else {
      if (blogStore.mode === modes.PREVIEW) {
        blogStore.changeMode();
      }
      blogStore.clearErrors();
      if (blogStore.author.length < 1) {
        blogStore.addErrorMessage({
          field: "author",
          message: "You must have an author",
        });
      }
      if (blogStore.title.length < 1) {
        blogStore.addErrorMessage({
          field: "title",
          message: "You must have a title",
        });
      }
      if (blogStore.content.length < 1) {
        blogStore.addErrorMessage({
          field: "content",
          message: "You must have content",
        });
      }
    }
  };

  return (
    <Observer>
      {() => (
        <React.Fragment>
          <div className="lg:flex lg:items-center lg:justify-between bg-white shadow p-20 pt-5 pb-5 min-w-0">
            <div className="flex-1">
              <h2 className="text-lg">Create New Blog</h2>
            </div>
            <div className="flex-1 flex items-center justify-end sm:items-stretch sm:justify-end">
              <button className="mx-1" onClick={save}>
                <span className="inline-flex items-center px-4 py-2 rounded-md shadow-sm text-sm font-medium text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  Save
                </span>
              </button>
              <button className="mx-1" onClick={blogStore.changeMode}>
                <span className="inline-flex items-center px-4 py-2 rounded-md shadow-sm text-sm font-medium text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  {blogStore.mode === modes.EDIT ? modes.PREVIEW : modes.EDIT}
                </span>
              </button>
            </div>
          </div>

          {blogStore.mode === modes.EDIT && <Editor blogStore={blogStore} />}
          {blogStore.mode === modes.PREVIEW && (
            <div className="shadow appearance-none border rounded py-2 px-3 mx-10 my-10">
              <MarkDownViewer content={blogStore.content} />
            </div>
          )}
        </React.Fragment>
      )}
    </Observer>
  );
};
