import React from "react";
import { Config, ConfigContext } from "../../components/configContext";
import { BlogSection } from "./BlogSection";
import { GallerySection } from "./GallerySection";
import { Hero } from "./Hero";

export const Home = () => (
  <ConfigContext.Consumer>
    {(config: Config) => (
      <React.Fragment>
        <div className="min-h-screen pb-10">
          <Hero />

          {config.features?.blogEnabled && (
            <div className="pb-10 mt-10">
              <BlogSection title="Recent Posts" />{" "}
            </div>
          )}
          {config.features?.galleryEnabled && (
            <div className="pb-10">
              <GallerySection />{" "}
            </div>
          )}
        </div>
      </React.Fragment>
    )}
  </ConfigContext.Consumer>
);
